<template>
  <div class="mt-3 md:mt-0">
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Action Menu For Marketplace"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <svg
            class="mt-1 w-6 h-5 text-oGray hover:text-oDark"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            ></path>
          </svg>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item
            class="font-medium text-14px"
            @click="onCreatePurchaseOrder"
          >
            Create Order
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
    <PurchaseOrderCreationAction
      :id="`purchase-order-${id}`"
      :marketplaceId="data.id"
      @refresh-page="refreshPage()"
    />
  </div>
</template>

<script>
export default {
  name: 'MoreActionsForMarketplace',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    PurchaseOrderCreationAction: () =>
      import('@/views/marketplace/modals/PurchaseOrderCreationAction.vue'),
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default() {
        return {
          status: false,
        }
      },
    },
  },

  methods: {
    onCreatePurchaseOrder() {
      this.$modal.show(`purchase-order-${this.id}`)
    },
    refreshPage() {
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped></style>
