var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('MarketPlaceAddEdit',{on:{"add-edit":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.marketPlaceManagement.orders.headline'),"hide-plus":false},on:{"plus":_vm.add}})],1)]),_c('FSTable',{attrs:{"fst-id":"marketplaceIndex","searchEnabled":false,"headers":_vm.tableHeaders,"endpoint":_vm.indexDataEndpoint},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{
              name: 'ViewMarketplaceProfile',
              params: { id: item.id, redirectPath: _vm.path },
            }}}),_c('FSTableRowItem',{staticClass:"flex items-center"},[_c('img',{staticClass:"w-8 h-8 rounded-full pr-4",attrs:{"src":item.brand_image}}),_vm._v(_vm._s(item.brand_name)+" ")]),_c('FSTableRowItem',{attrs:{"text":item.fleet.name,"to":{
              name: 'ViewFleetProfile',
              params: { id: item.fleet.id, redirectPath: _vm.path },
            }}}),_c('FSTableRowItem',{attrs:{"text":_vm.getFormattedDateTime(item.order_start_date, 'D MMM, YYYY')}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.getFormattedDateTime(item.order_end_date, 'D MMM, YYYY'))+" ("),_c('TimeAgo',{attrs:{"title":item.order_end_date,"datetime":item.order_end_date,"auto-update":60}}),_vm._v(") ")],1),_c('FSTableRowItem',{attrs:{"text":item.total_vehicle_number}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.fleet.country.currency_symbol)+" "+_vm._s(item.total_order_value))]),_c('FSTableRowItem',{attrs:{"text":item.total_purchased_vehicle}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.fleet.country.currency_symbol)+" "+_vm._s(item.total_purchased_amount))]),_c('FSTableRowItem',[_c('XStatus',{attrs:{"text":_vm.getItemStatusTitle(item.order_status),"variant":_vm.getItemVariant(item.order_status),"profile":"marketplace"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActions',{attrs:{"id":itemIndex,"data":item},on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }